import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  SafeAreaView,
  ScrollView,
  Image,
} from "react-native";
import { RootStackParamList, Doctor } from "../utils/types";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

type Props = {
  route: RouteProp<RootStackParamList, "Doctors">;
  navigation: StackNavigationProp<RootStackParamList, "Doctors">;
};

const DoctorsPage = ({ route, navigation }: Props) => {
  const { building, doctorList, roomList } = route.params;
  const { name: buildingName, id: buildingId } = building;

  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  useEffect(() => {
    i18next.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  return (
    <SafeAreaView
      className="bg-[#ADCDF6] max-w-screen"
      style={{ height: "100%", maxHeight: "100dvh" }}
    >
      <View className="flex flex-row justify-between items-center py-2 px-4 bg-[#FFFFFB]">
        <Image
          className="w-[35px] h-[35px]"
          source={require("../assets/logo.png")}
        />
        <Text className="text-black text-lg">{buildingName}</Text>
      </View>

      <ScrollView className="flex-1">
        <View className="flex-1 items-center">
          {Array.isArray(doctorList) && doctorList.length > 0 ? (
            doctorList.map((doctor) => (
              <TouchableOpacity
                key={doctor.id}
                onPress={() =>
                  navigation.navigate("Rooms", { building, doctor, roomList })
                }
                className="flex-row justify-between bg-white w-[80%] p-4 m-2 border-2 rounded-[10px] border-gray-200"
              >
                <Text className="text-lg">{doctor.name}</Text>
                {/* <Image
                  source={require('../assets/right-arrow.png')}
                  className="w-[20px] h-[20px] my-auto"
                /> */}
              </TouchableOpacity>
            ))
          ) : (
            <Text>No doctors found</Text>
          )}
        </View>
      </ScrollView>

      <View className="px-4 py-3 flex flex-row justify-between bg-[#FFFFFB] w-full items-center">
        <Text className="font-bold text-xl text-black">{buildingName}</Text>
        {/* <Dropdown
          data={languages}
          labelField="key"
          valueField="value"
          value={selectedLanguage}
          onChange={(e) => setSelectedLanguage(e.value)}
          renderLeftIcon={() => (
            <Image
              className="w-4 h-4 mr-2"
              source={require('../assets/globe.png')}
            />
          )}
          style={{
            height: 40,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 8,
            paddingHorizontal: 8,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        /> */}
      </View>
    </SafeAreaView>
  );
};

export default DoctorsPage;
