// App.js
import React, { useState, useEffect } from "react";
import Toast from "react-native-toast-message";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import LoadingPage from "./components/LoadingPage";
import LoginPage from "./screens/Login";
import RequestsPage from "./screens/Requests";
import DoctorsPage from "./screens/Doctors";
import RoomsPage from "./screens/Rooms";
import QRCodeScannerPage from "./components/QRCodeScannerPage";
import ViewRequestPage from "./components/ViewRequestPage";
import { useTranslation } from "react-i18next";

import { NativeWindStyleSheet } from "nativewind";
import { SafeAreaProvider } from "react-native-safe-area-context";

NativeWindStyleSheet.setOutput({
  default: "native",
});

const Stack = createStackNavigator();

const App = () => {
  const { t } = useTranslation();
  return (
    <SafeAreaProvider>
      <NavigationContainer>
        <Stack.Navigator initialRouteName="Login">
          <Stack.Screen
            name="Login"
            component={LoginPage}
            options={{
              headerLeft: null,
              headerTitle: "PhysicianCompanion",
            }}
          />
          <Stack.Screen
            name="Doctors"
            component={DoctorsPage}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Rooms"
            component={RoomsPage}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Requests"
            component={RequestsPage}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="QRCodeScanner"
            component={QRCodeScannerPage}
            options={{ headerTitle: "PhysicianCompanion" }}
          />
          <Stack.Screen
            name="ViewRequests"
            component={ViewRequestPage}
            options={{ headerTitle: t("View My Requests") }}
          />
        </Stack.Navigator>
        <Toast ref={(ref) => Toast.setRef(ref)} />
      </NavigationContainer>
    </SafeAreaProvider>
  );
};

export default App;
