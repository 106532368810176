import React, { useState, useEffect, useLayoutEffect } from "react";
import { View, Image, Text, Pressable, FlatList, Linking } from "react-native";
import { RouteProp, useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { deleteRequest, fetchRequests } from "../utils/services";
import { Ionicons } from "@expo/vector-icons";
import "../lang/i18n";
import { useTranslation } from "react-i18next";
import { RootStackParamList } from "../utils/types";

type Props = {
  route: RouteProp<RootStackParamList, "ViewRequests">;
};

const ViewRequestPage = ({ route }: Props) => {
  const { doctor } = route.params;
  const { id: doctorId } = doctor;
  const navigation = useNavigation();
  const { t } = useTranslation();

  const [requests, setRequests] = useState<Request[]>([]);

  const getRequests = async () => {
    if (doctorId) {
      const requests = await fetchRequests(doctorId);
      setRequests(requests);
    }
  };

  useEffect(() => {
    getRequests();
  }, [doctor]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Pressable
          className="p-5 flex flex-row justify-center items-center"
          onPress={() => getRequests()}
        >
          <Ionicons name="refresh" size={32} color="black" />
        </Pressable>
      ),
    });
  }, [navigation]);

  // Function to delete an item
  const deleteItem = async (itemId: string) => {
    const request = requests.find((r: Request) => r.requestType === itemId);
    if (request) {
      try {
        await deleteRequest(request.id);
      } catch (e) {
        console.error(e);
      }
    }

    const updatedRequest = requests.filter(
      (r: Request) => r.requestType !== itemId
    );
    setRequests(updatedRequest);
  };

  return (
    <View className="items-center bg-white h-full">
      <FlatList
        className="flex flex-col gap-8 p-2 m-2 w-4/5"
        data={requests}
        renderItem={({ item }) => (
          <View className="flex flex-row justify-between border-2 my-3 p-5 rounded-md bg-[#ADCDF6] border-gray-500">
            <Text className="text-[20px]">
              {item.requestType === "Misc Assistance" && item.detailMisc
                ? item.detailMisc
                : t(item.requestType)}
            </Text>
            <Pressable onPress={() => deleteItem(item.requestType)}>
              <Image
                className="w-[20px] h-[20px]"
                source={require("../assets/close.png")}
              />
            </Pressable>
          </View>
        )}
        ListEmptyComponent={
          <View className="flex justify-center items-center p-8">
            <Text className="text-[20px]">{t("No requests")}</Text>
          </View>
        }
      />
    </View>
  );
};

export default ViewRequestPage;
