import { DoctorRequest, Room } from "./types";

// const BASE_URL = "http://localhost:3000/api";
const BASE_URL = "https://amg.physiciancompanion.com/api";

export const fetchRequests = async (doctorId: string) => {
  const response = await fetch(`${BASE_URL}/doctor/${doctorId}/request`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};

export const fetchRequestTypes = async (doctorId: string) => {
  const response = await fetch(`${BASE_URL}/requestType/${doctorId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data.sort((a: any, b: any) => a.priority - b.priority);
};

export const fetchDoctors = async (buildingId: string) => {
  const response = await fetch(`${BASE_URL}/building/${buildingId}/doctors`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};

export const fetchDoctorInformation = async (doctorId: string) => {
  const response = await fetch(`${BASE_URL}/doctor/${doctorId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};

export const fetchBuildingInformation = async (buildingId: string) => {
  const response = await fetch(`${BASE_URL}/building/${buildingId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};

export const fetchRoomList = async (buildingId: string) => {
  const response = await fetch(`${BASE_URL}/building/${buildingId}/room`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();

  return data.sort((a: Room, b: Room) => a.name.localeCompare(b.name));
};

export const fetchBedInformation = async (bedId: string) => {
  const response = await fetch(`${BASE_URL}/bed?id=${bedId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};

export const createRequest = async ({
  doctorId,
  room,
  requestType,
  equipment,
  detail,
}: DoctorRequest) => {
  const response = await fetch(`${BASE_URL}/request`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      doctorId,
      room,
      requestType,
      equipment,
      detail,
    }),
  });
  return response;
};

export const createPatient = async (room: string, bed: string) => {
  const response = await fetch(`${BASE_URL}/patient`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      room,
      bed,
    }),
  });
  const data = await response.json();
  return data.id;
};

export const deleteRequest = async (requestId: string) => {
  const response = await fetch(`${BASE_URL}/request/${requestId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      deletedByDoctor: true,
    }),
  });
  const data = await response.json();
  return data;
};
