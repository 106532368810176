import React, { useEffect } from "react";
import { StatusBar, Text, TouchableOpacity, View } from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../utils/types";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  fetchBuildingInformation,
  fetchDoctors,
  fetchRoomList,
} from "../utils/services";

type Props = { navigation: StackNavigationProp<RootStackParamList, "Login"> };

const LoginPage = ({ navigation }: Props) => {
  useEffect(() => {
    const checkBuildingId = async () => {
      const cachedBuildingId = await AsyncStorage.getItem("buildingId");
      const urlBuildingId = new URLSearchParams(window?.location.search).get(
        "buildingId"
      );
      const buildingId = cachedBuildingId ?? urlBuildingId;

      if (buildingId) {
        try {
          const building = await fetchBuildingInformation(buildingId);
          console.log("building", building);

          const doctorList = await fetchDoctors(buildingId);
          console.log("doctor", doctorList);

          const roomList = await fetchRoomList(buildingId);
          navigation.navigate("Doctors", { building, doctorList, roomList });
        } catch (e) {
          console.warn(e);
        }
      }
    };

    checkBuildingId();
  }, []);

  return (
    <View
      className={`flex-1 pt-[${StatusBar.currentHeight}px] bg-[#ecf0f1] p-[10px]`}
    >
      <Text className="mt-[30px] text-[28px] font-bold text-center">
        Scan QR Code
      </Text>
      <Text className="m-[15px] pb-[10px] font-normal text-[18px] text-center">
        Please scan the QR code provided at your clinic to connect to your
        services
      </Text>
      <TouchableOpacity
        className="rounded-[10px] mt-[16px] py-[8px] border-[2px] border-[#20232a] bg-white w-[80%] self-center"
        onPress={() => navigation.navigate("QRCodeScanner")}
      >
        <Text className="text-center text-[20px] font-semibold">
          Click to Scan QR Code
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default LoginPage;
